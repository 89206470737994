<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування новини" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Label *"
                  v-model="label"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-input
                  label="Header *"
                  v-model="header"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.header"
                  :error="!!errors.header"
                  :error-messages="errors.header"
                />
                <va-input
                  label="Subheader"
                  v-model="subheader"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.subheader"
                  :error="!!errors.subheader"
                  :error-messages="errors.subheader"
                />
                <div style="margin-bottom: 15px;">
                  <span>Description *</span>
                  <ckeditor v-model="desc" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-card class="mb-3" title="Image *">
                  <div v-if="imgExisting">
                    <a style="display: block;" target="_blank" :href="imgExisting"><img style="max-width: 400px;" :src="imgExisting"></a>
                    <div><va-button small color="danger" @click="imgExisting = deleteImage(imgId) ? '' : imgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    @input="delete errors.img"
                    type="gallery"
                    dropzone
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.img" style="color: red;">{{ this.errors.img[0] }}</div>
                  <va-button @click="uploadImage(img[0])">Завантажити</va-button>
                </va-card>
                <va-input
                  label="Link Name"
                  v-model="link_name"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.link_name"
                  :error="!!errors.link_name"
                  :error-messages="errors.link_name"
                />
                <va-input
                  label="Link href"
                  v-model="link_href"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.link_href"
                  :error="!!errors.link_href"
                  :error-messages="errors.link_href"
                />
                <div style="margin-bottom: 15px;">
                  <span>Text contents *</span>
                  <ckeditor v-model="text_contents_text" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <div style="margin-bottom: 15px;">
                  <span>trailingText *</span>
                  <ckeditor v-model="trailingText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-input
                  label="Stats text"
                  v-model="stats_text"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.stats_text"
                  :error="!!errors.stats_text"
                  :error-messages="errors.stats_text"
                />
                <va-input
                  label="Key"
                  v-model="key"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.key"
                  :error="!!errors.key"
                  :error-messages="errors.key"
                />
                <va-input
                  label="Alias *"
                  v-model="alias"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.alias"
                  :error="!!errors.alias"
                  :error-messages="errors.alias"
                />
                <va-toggle label="Published" small v-model="published"/>
                <va-button @click="submit()">Save</va-button>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-data-table
                  :fields="quoteFields"
                  :data="quotes"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished('quotes',props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template>

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-capital-quotes-edit', params: { id: props.rowData.id } }"
                    >
                      Редагувати
                    </va-button>
                    <!--                    <va-button
                      small
                      @click="remove(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>-->
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-data-table
                  :fields="companyContentsFields"
                  :data="companyContents"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished('contents',props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template>

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-capital-contents-edit', params: { id: props.rowData.id } }"
                    >
                      Редагувати
                    </va-button>
                    <!--                    <va-button
                      small
                      @click="remove(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>-->
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 3">
                <va-data-table
                  :fields="statsFields"
                  :data="stats"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished(props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template>

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-capital-stats-edit', params: { id: props.rowData.id } }"
                    >
                      Редагувати
                    </va-button>
                    <!--                    <va-button
                      small
                      @click="remove(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>-->
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 4">
                <va-data-table
                  :fields="numListFields"
                  :data="numList"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished(props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template>

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-capital-num-list-edit', params: { id: props.rowData.id } }"
                    >
                      Редагувати
                    </va-button>
                    <!--                    <va-button
                      small
                      @click="remove(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>-->
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 5">
                <va-input
                  label="Title"
                  v-model="seoTitle"
                />
                <va-input
                  label="Description"
                  v-model="seoDescription"
                />
                <va-input
                  label="Keywords"
                  v-model="seoKeywords"
                />
                <div style="margin-bottom: 15px;">
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <!-- <span>Image</span>
                <va-card class="mb-3" title="Image">
                  <div v-if="seoImageExisting">
                    <a style="display: block;" target="_blank" :href="seoImageExisting"><img style="max-width: 400px;" :src="seoImageExisting"></a>
                    <div><va-button small color="danger" @click="seoImageExisting = deleteImage(seoImageId) ? '' : seoImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="seoImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', 'SeoEntityImage', 'SeoEntity[image]', seoImage[0], 'common\\models\\SeoEntity')">Завантажити</va-button>
                </va-card> -->
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {

      header: '',
      subheader: '',
      desc: '',
      key: 0,
      alias: '',
      link_name: '',
      link_href: '',
      text_contents_text: '',
      trailingText: '',
      stats_text: '',
      numList: null,
      quotes: null,
      stats: null,
      companyContents: null,

      img: [],
      imgNew: '',
      imgId: '',
      imgExisting: '',
      minicardImg: [],
      minicardImgId: '',
      minicardImgExisting: '',
      label: '',
      published_at: '08-05-2020 14:10:00',
      content: '',
      position: '',
      published: false,

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageId: '',
      seoImageExisting: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        autoParagraph: false,
        enterMode: 2,
        shiftEnterMode: 2,
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AUTOSTRADA_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      sign: '',

      errors: [],

      tabTitles: ['Main', 'Quote', 'Contents', 'Stats', 'NumList', 'Seo'],
      tabValue: 0,
    }
  },
  created () {
    if (!this.$attrs.id) {
      const today = new Date()
      this.published_at = today.toLocaleDateString('uk-UA') + ' ' + today.toLocaleTimeString('uk-UA')
      const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
      this.sign = generateRandomString(32)
    }
    this.fetch()
  },
  methods: {
    togglePublished (route, id) {
      axios.put(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/${route}/${id}`, {
        published: !!this.news.find((item) => item.id === id).published,
      })
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    uploadImage (file) {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.imgNew = response.data.patch
          this.imgId = response.data.id
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/upload/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        label: this.label,
        header: this.header,
        alias: this.alias,
        subheader: this.subheader,
        desc: this.desc,
        key: this.key,
        img: this.imgNew,
        text_contents_text: this.text_contents_text,
        link_name: this.link_name,
        link_href: this.link_href,
        trailingText: this.trailingText,
        published: this.published,
        stats_text: this.stats_text,
        seo_title: this.seoTitle,
        seo_text: this.seoText,
        seo_desc: this.seoDescription,
        seo_keywords: this.seoKeywords,
        seo_image: this.seoImage,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/companies/${this.$attrs.id}`, data)
          .then(response => {
            this.$router.push({ name: 'ms-capital-companies' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/companies`, data)
          .then(response => {
            this.$router.push({ name: 'ms-capital-companies' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/companies/${this.$attrs.id}`)
          .then(response => {
            console.log(response.data)
            this.label = response.data.label
            this.key = response.data.key
            this.header = response.data.header
            this.subheader = response.data.subheader
            this.desc = response.data.desc
            this.imgNew = response.data.img
            this.alias = response.data.alias
            this.imgExisting = process.env.VUE_APP_MS_URL + response.data.img
            this.text_contents_text = response.data.text_contents_text
            this.link_name = response.data.link_name
            this.link_href = response.data.link_href
            this.trailingText = response.data.trailingText
            this.published = !!response.data.published
            this.stats_text = response.data.stats_text

            this.quotes = response.data.quotes
            this.stats = response.data.stats
            this.companyContents = response.data.companyContents
            this.numList = response.data.numList

            this.seoTitle = response.data.seo_title
            this.seoDescription = response.data.seo_desc
            this.seoKeywords = response.data.seo_keywords
            this.seoText = response.data.seo_text
            this.seoImage = response.data.seo_image
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
  computed: {
    numListFields () {
      return [
        {
          name: 'header',
          title: 'Header',
          width: '40%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '20%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    quoteFields () {
      return [
        {
          name: 'content',
          title: 'Content',
          width: '40%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '20%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    companyContentsFields () {
      return [
        {
          name: 'desc',
          title: 'Desc',
          width: '40%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '20%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    statsFields () {
      return [
        {
          name: 'desc',
          title: 'Desc',
          width: '40%',
        },
        {
          title: 'Опубліковано',
          name: '__slot:toggle',
          width: '20%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
